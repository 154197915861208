import React from 'react';

export default function CookiePolicy() {
  return (
    <div className="container mx-auto px-4 py-32">
      <h1 className="text-2xl font-bold text-center mb-4">Cookie Policy</h1>
      <p>
        At Network Data and Solutions, we use cookies to enhance your experience
        on our website. This policy explains what cookies are, how we use them,
        and how you can manage their use.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">What are cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device to collect
        standard internet log information and visitor behavior information. When
        you visit our sites, we may collect information from you automatically
        through cookies or similar technology.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        How do we use cookies?
      </h2>
      <p>
        We use cookies in a range of ways to improve your experience on our
        website
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        What types of cookies do we use?
      </h2>
      <p>
        There are a number of different types of cookies, however, our website
        uses:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <strong>Functionality</strong> – We use these cookies so that we
          recognize you on our website and remember your previously selected
          preferences. These could include what language you prefer and location
          you are in.
        </li>
      </ul>
      <h2 className="text-xl font-semibold mt-6 mb-2">How to manage cookies</h2>
      <p>
        You can set your browser not to accept cookies, and the above websites
        tell you how to remove cookies from your browser. However, in a few
        cases, some of our website features may not function as a result.
      </p>
      <p className="mt-4">
        For more information on how to manage and delete cookies, visit{' '}
        <a
          href="https://en.wikipedia.org/wiki/HTTP_cookie"
          className="link link-accent"
        >
          HTTP cookie
        </a>
        ,{' '}
        <a href="www.allaboutcookies.org" className="link link-accent">
          allaboutcookies.org
        </a>
      </p>
    </div>
  );
}
