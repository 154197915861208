import React from "react";

const SVGHex: React.FC = () => {
  return (
    <svg
      width="100wh"
      height="100vh"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current text-accent min-hsc"
    >
      <g>
        <g transform="translate(37 227)">
          <path d="M0 -48.6L42.1 -24.3L42.1 24.3L0 48.6L-42.1 24.3L-42.1 -24.3Z" />
        </g>
        <g transform="translate(67 439)">
          <path d="M0 -42L36.4 -21L36.4 21L0 42L-36.4 21L-36.4 -21Z" />
        </g>
        <g transform="translate(419 204)">
          <path d="M0 -46L39.8 -23L39.8 23L0 46L-39.8 23L-39.8 -23Z" />
        </g>
        <g transform="translate(624 44)">
          <path d="M0 -18L15.6 -9L15.6 9L0 18L-15.6 9L-15.6 -9Z" />
        </g>
        <g transform="translate(585 488)">
          <path d="M0 -23L19.9 -11.5L19.9 11.5L0 23L-19.9 11.5L-19.9 -11.5Z" />
        </g>
        <g transform="translate(889 451)">
          <path d="M0 -39L33.8 -19.5L33.8 19.5L0 39L-33.8 19.5L-33.8 -19.5Z" />
        </g>
        <g transform="translate(121 548)">
          <path d="M0 -42L36.4 -21L36.4 21L0 42L-36.4 21L-36.4 -21Z" />
        </g>
        <g transform="translate(415 455)">
          <path d="M0 -28L24.2 -14L24.2 14L0 28L-24.2 14L-24.2 -14Z" />
        </g>
        <g transform="translate(588 217)">
          <path d="M0 -37L32 -18.5L32 18.5L0 37L-32 18.5L-32 -18.5Z" />
        </g>
        <g transform="translate(714 535)">
          <path d="M0 -41L35.5 -20.5L35.5 20.5L0 41L-35.5 20.5L-35.5 -20.5Z" />
        </g>
        <g transform="translate(792 442)">
          <path d="M0 -23L19.9 -11.5L19.9 11.5L0 23L-19.9 11.5L-19.9 -11.5Z" />
        </g>
        <g transform="translate(859 137)">
          <path d="M0 -34L29.4 -17L29.4 17L0 34L-29.4 17L-29.4 -17Z" />
        </g>
        <g transform="translate(524 138)">
          <path d="M0 -39L33.8 -19.5L33.8 19.5L0 39L-33.8 19.5L-33.8 -19.5Z" />
        </g>
        <g transform="translate(276 252)">
          <path d="M0 -21L18.2 -10.5L18.2 10.5L0 21L-18.2 10.5L-18.2 -10.5Z" />
        </g>
        <g transform="translate(265 55)">
          <path d="M0 -25L21.7 -12.5L21.7 12.5L0 25L-21.7 12.5L-21.7 -12.5Z" />
        </g>
        <g transform="translate(419 61)">
          <path d="M0 -28L24.2 -14L24.2 14L0 28L-24.2 14L-24.2 -14Z" />
        </g>
        <g transform="translate(337 373)">
          <path d="M0 -23L19.9 -11.5L19.9 11.5L0 23L-19.9 11.5L-19.9 -11.5Z" />
        </g>
        <g transform="translate(833 320)">
          <path d="M0 -39L33.8 -19.5L33.8 19.5L0 39L-33.8 19.5L-33.8 -19.5Z" />
        </g>
        <g transform="translate(712 242)">
          <path d="M0 -22L19.1 -11L19.1 11L0 22L-19.1 11L-19.1 -11Z" />
        </g>
        <g transform="translate(74 323)">
          <path d="M0 -23L19.9 -11.5L19.9 11.5L0 23L-19.9 11.5L-19.9 -11.5Z" />
        </g>
        <g transform="translate(381 586)">
          <path d="M0 -30L26 -15L26 15L0 30L-26 15L-26 -15Z" />
        </g>
        <g transform="translate(318 162)">
          <path d="M0 -47L40.7 -23.5L40.7 23.5L0 47L-40.7 23.5L-40.7 -23.5Z" />
        </g>
        <g transform="translate(203 361)">
          <path d="M0 -43L37.2 -21.5L37.2 21.5L0 43L-37.2 21.5L-37.2 -21.5Z" />
        </g>
        <g transform="translate(502 584)">
          <path d="M0 -18L15.6 -9L15.6 9L0 18L-15.6 9L-15.6 -9Z" />
        </g>
        <g transform="translate(210 589)">
          <path d="M0 -18L15.6 -9L15.6 9L0 18L-15.6 9L-15.6 -9Z" />
        </g>
        <g transform="translate(141 9)">
          <path d="M0 -21L18.2 -10.5L18.2 10.5L0 21L-18.2 10.5L-18.2 -10.5Z" />
        </g>
        <g transform="translate(1200 300)">
          <path d="M0 -60L52 -30L52 30L0 60L-52 30L-52 -30Z" />
        </g>
        <g transform="translate(1500 700)">
          <path d="M0 -45L39 -22.5L39 22.5L0 45L-39 22.5L-39 -22.5Z" />
        </g>
        <g transform="translate(1800 200)">
          <path d="M0 -55L47.6 -27.5L47.6 27.5L0 55L-47.6 27.5L-47.6 -27.5Z" />
        </g>
        <g transform="translate(1700 900)">
          <path d="M0 -40L34.6 -20L34.6 20L0 40L-34.6 20L-34.6 -20Z" />
        </g>
        <g transform="translate(900 800)">
          <path d="M0 -50L43.3 -25L43.3 25L0 50L-43.3 25L-43.3 -25Z" />
        </g>
        <g transform="translate(1100 100)">
          <path d="M0 -35L30.3 -17.5L30.3 17.5L0 35L-30.3 17.5L-30.3 -17.5Z" />
        </g>
        <g transform="translate(1300 500)">
          <path d="M0 -45L39 -22.5L39 22.5L0 45L-39 22.5L-39 -22.5Z" />
        </g>
        <g transform="translate(1600 400)">
          <path d="M0 -55L47.6 -27.5L47.6 27.5L0 55L-47.6 27.5L-47.6 -27.5Z" />
        </g>
        <g transform="translate(50 800)">
          <path d="M0 -40L34.6 -20L34.6 20L0 40L-34.6 20L-34.6 -20Z" />
        </g>
        <g transform="translate(300 1000)">
          <path d="M0 -30L26 -15L26 15L0 30L-26 15L-26 -15Z" />
        </g>
      </g>
    </svg>
  );
};

export default SVGHex;
