import React from 'react';

export default function TermsOfService() {
  return (
    <div className="container mx-auto px-4 py-32">
      <h1 className="text-2xl font-bold text-center mb-4">Terms of Use</h1>
      <p>
        By accessing or using the Network Data and Solutions website or any of
        our mobile applications (collectively, the "Service"), you signify that
        you have read, understand, and agree to be bound by these Terms of Use
        ("Terms") and any other applicable law. If you do not agree to these
        Terms, please do not use the Service.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Eligibility</h2>
      <p>
        The Service is intended only for users who are at least 18 years old. If
        you are under 18, you may use the Service only with involvement and
        approval of a parent or guardian.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Accounts and Registration
      </h2>
      <p>
        To access certain features of the Service, you may need to create an
        account. You must provide accurate and complete information during the
        registration process. You are responsible for maintaining the security
        and confidentiality of your account credentials, and you are responsible
        for any activities that occur under your account.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Prohibited Activities</h2>
      <p>
        You agree not to engage in any of the following activities while using
        the Service:
      </p>
      <ul className="list-disc list-inside">
        <li>
          Impersonating another person or entity or misrepresenting your
          affiliation with a person or entity.
        </li>
        <li>
          Using the Service for any illegal or unauthorized purpose, including
          violating any applicable laws or regulations.
        </li>
        <li>
          Posting or transmitting any content that is abusive, harassing,
          threatening, obscene, defamatory, or otherwise objectionable.
        </li>
        <li>
          Disrupting or interfering with the security or use of the Service or
          any connected network, or engaging in distributed denial-of-service
          attacks.
        </li>
        <li>
          Using any data mining, robots, or similar data gathering or extraction
          methods.
        </li>
      </ul>
      <h2 className="text-xl font-semibold mt-6 mb-2">Intellectual Property</h2>
      <p>
        The Service and all content within it, including but not limited to
        text, logos, trademarks, trade names, service marks, images, and
        software, are the property of Network Data and Solutions or its
        licensors and are protected by copyright, trademark, and other
        intellectual property laws. You may not reproduce, modify, distribute,
        sell, or exploit any part of the Service without our express written
        permission.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Third-Party Links</h2>
      <p>
        The Service may contain links to third-party websites or services that
        are not owned or controlled by us. We have no control over, and assume
        no responsibility for, the content, privacy policies, or practices of
        any third-party websites or services. You further acknowledge and agree
        that Network Data and Solutions shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be
        caused by or in connection with use of or reliance on any such content,
        goods, or services available on or through any such websites or
        services.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Disclaimer of Warranties
      </h2>
      <p>
        The Service is provided on an "as is" and "as available" basis without
        any representations or warranties, express or implied. Network Data and
        Solutions makes no warranties of any kind, whether express or implied,
        including but not limited to the implied warranties of merchantability,
        fitness for a particular purpose, non-infringement, and course of
        performance.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Limitation of Liability
      </h2>
      <p>
        In no event shall Network Data and Solutions be liable for any direct,
        indirect, incidental, special, consequential, or punitive damages,
        including but not limited to, loss of profits, data, use, goodwill, or
        other intangible losses, resulting from (i) your access to or use of the
        Service; (ii) any conduct or content of any third party on the Service;
        (iii) any content obtained from the Service; or (iv) unauthorized
        access, use, or alteration of your transmissions or content, whether
        based on warranty, contract, tort (including negligence), or any other
        legal theory, whether or not we have been informed of the possibility of
        such damage, and even if a remedy set forth herein is found to have
        failed of its essential purpose.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless Network Data and
        Solutions and its officers, directors, employees, contractors,
        licensors, and suppliers from and against any claims, liabilities,
        damages, judgments, awards, losses, costs, expenses, or fees (including
        reasonable attorneys' fees) arising out of or relating to your violation
        of these Terms or your use of the Service, including, but not limited
        to, your User Content, your violation of any third-party right,
        including without limitation any copyright, property, or privacy right,
        or any claim that your User Content caused damage to a third party.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of NJ/USA, without regard to its conflict of law provisions.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Changes to These Terms
      </h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will provide at least 30
        days' notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion. By continuing
        to access or use our Service after any revisions become effective, you
        agree to be bound by the revised terms. If you do not agree to the new
        terms, you are no longer authorized to use the Service.
      </p>
    </div>
  );
}
