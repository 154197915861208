export default function Footer() {
  return (
    <footer className="footer p-10 bg-base-200 text-base-content absolute">
      <aside>
        <div className="flex flex-row justify-between w-full">
          <img
            src="\imgs\netds_logo.png"
            alt="NetDs Logo"
            className="h-10 w-auto"
          />
          <img
            src="\imgs\VeteranOwned_Logo_300x300.png"
            className="h-12 w-auto"
            alt="veteran owned business"
          />
        </div>
        <p>Copyright © 2024 - All right reserved by NETDS LLC</p>
      </aside>

      <nav>
        <h6 className="footer-title">Company</h6>
        <a className="link link-hover" href="/about">
          About us
        </a>
        <a className="link link-hover" href="/contact">
          Contact
        </a>
        <a className="link link-hover" href="/jobs">
          Jobs
        </a>
        <a className="link link-hover">Press kit</a>
      </nav>

      <nav>
        <h6 className="footer-title">Legal</h6>
        <a className="link link-hover" href="/termsofservice">
          Terms of use
        </a>
        <a className="link link-hover" href="/privacypolicy">
          Privacy policy
        </a>
        <a className="link link-hover" href="/cookiepolicy">
          Cookie policy
        </a>
      </nav>

      <nav>
        <h6 className="footer-title">Knowledge</h6>
        <a className="link link-hover" href="https://www.comptia.org/home">
          CompTIA
        </a>
        <a
          className="link link-hover"
          href="https://www.cisa.gov/stopransomware"
        >
          Stop Ransomware
        </a>
        <a
          className="link link-hover"
          href="https://www.forbes.com/advisor/business/what-is-managed-service-provider/"
        >
          What is an MSP?
        </a>
      </nav>
    </footer>
  );
}
