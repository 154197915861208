import { useState } from 'react';
import ServiceCard from '../components/ServiceCards';
import { serviceItems } from '../data/ServiceData';
import { ServiceObject } from '../Interfaces';
import SVGHex from '../components/SVGHex';
import BouncingDots from '../components/BouncingDots';

const Services: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceData, setServiceData] = useState<ServiceObject[]>(serviceItems);

  return (
    <div className="py-32">
      {/* SVGComponent positioned as background with absolute positioning */}
      <div className="absolute top-1/2 left-1/2 inset-0 z-0 pointer-events-none">
        <SVGHex />
      </div>

      {/* Main content with sufficient padding to ensure visibility over the SVG */}
      <div className="relative z-10 flex flex-col items-center justify-center p-2 pt-40 xl:pt-8">
        {loading ? (
          <BouncingDots />
        ) : (
          <div className="flex flex-col gap-2">
            <h2 className="text-4xl font-bold mb-8 text-center">
              Our Services📜
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {serviceData.map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
