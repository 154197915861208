import emailjs from '@emailjs/browser';
import debounce from 'lodash/debounce';

export class EmailJSHelper {
  // Debounce the function to prevent multiple calls to the emailjs.send function
  public static sendEmailDebounced = debounce(this.sendEmail, 1000);

  private static async sendEmail(templateParams: any) {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY!);
    // console log key
    const response = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID!,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
      templateParams!
    );
    return response;
  }
}
