import React from 'react';

export default function Jobs() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-base-200 py-12 px-4">
      <div className="flex flex-col gap-12">
        <div className="bg-base-200 p-8 rounded-lg shadow-lg max-w-4xl">
          <h2 className="text-3xl font-bold mb-4">Our Company Culture</h2>
          <p className="text-md mb-4">
            At Network Data and Solutions, we foster a culture of
            self-motivation and continuous learning. Our team members are eager
            to grow and collaborate, bringing their best selves to work every
            day.
          </p>
          <p className="text-md mb-4">
            We value integrity and self-awareness, believing that these
            qualities are essential for personal and professional development.
            Our employees are encouraged to take initiative, embrace challenges,
            and strive for excellence in all they do.
          </p>
          <p className="text-md mb-4">
            We believe that anyone can learn and succeed with the right mindset.
            Our supportive environment is designed to help each individual
            unlock their full potential and achieve their goals.
          </p>
          <p className="text-md mb-4">
            We take care of each other and look out for one another.
            Understanding the difficulties of modern-day life, we respect and
            promote a healthy work-life balance. We believe that a supportive
            and balanced environment leads to happier, more productive team
            members.
          </p>
        </div>

        <div className="flex flex-col justify-center items-center">
          <h1 className="text-4xl font-extrabold mb-4">Positions</h1>
          <p className="text-lg max-w-md">
            We are not currently hiring. Please check back later for any job
            openings.
          </p>
        </div>
      </div>
    </div>
  );
}
