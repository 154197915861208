import React, { useState } from "react";
import { ServiceObject } from "../Interfaces";

// Props interface for ServiceCard component
interface ServiceCardProps {
  service: ServiceObject;
}

// ServiceCard component
const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
  const [collapsed, setCollapsed] = useState<boolean[]>(
    (service.key_features ?? []).map(() => true)
  );

  const toggleCollapse = (index: number) => {
    setCollapsed((prev = []) =>
      prev.map((item, idx) => (idx === index ? !item : item))
    );
  };

  return (
    <div
      className={`p-6 rounded-xl shadow-lg transform transition duration-500 bg-base-300 cursor-pointer border`}
    >
      <div className="flex items-center space-x-4 py-4">
        <div className={`p-2 rounded-full bg-accent fill-current text-base`}>
          {service.SVG}
        </div>
        <h2 className={`text-2xl font-bold text-base-content`}>
          {service.title}
        </h2>
      </div>
      <div className="border rounded-xl p-2 mt-3">
        <p className={`text-base-content`}>{service.description}</p>
      </div>
      <div className="mt-4 border rounded-xl p-4">
        {" "}
        {/* Display Features */}
        <h3 className={`text-lg font-semibold text-center`}>Key Features</h3>
        {service.key_features &&
          service.key_features.map((feature, index) => (
            <div key={index}>
              <h2
                className="text-lg font-semibold cursor-pointer flex items-center"
                onClick={() => toggleCollapse(index)}
              >
                {feature.name}
                <span className="ml-2">
                  {collapsed[index] ? (
                    <svg
                      className="w-4 h-4 transform rotate-0 transition-transform duration-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      className="w-4 h-4 transform rotate-180 transition-transform duration-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  )}
                </span>
              </h2>
              {!collapsed[index] && (
                <p className={`text-base-content ml-3`}>{feature.desc}</p>
              )}
            </div>
          ))}
      </div>
      {/* Pricing Section */}
      <div className="grid grid-cols-2 w-full">
        {service.pricing &&
          service.pricing.map((price: any, index: any) => (
            <div key={index} className="mt-2">
              <h3 className={`text-lg font-semibold `}>{price.tier}</h3>
              <p
                className={`text-sm badge ${
                  price.price === "Immediately"
                    ? "badge-error text-white"
                    : "badge-primary"
                }`}
              >
                {price.price}
              </p>
              <ul className="list-disc list-inside text-sm">
                {price.features &&
                  price.features.map((feature: any, fIndex: any) => (
                    <li
                      key={fIndex}
                      className={`text-${service.color}-content`}
                    >
                      {feature}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServiceCard;
