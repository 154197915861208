import { useEffect, useState } from 'react';
import { themeChange } from 'theme-change';
import ThemeChanger from './ThemeChanger';

const navigationLinks = [
  { name: 'Schedule an Appointment', href: 'https://appointment.netds.tech' },
  { name: 'Contact Us', href: '/contact' },
  // { name: 'Services', href: '/services' },
];

const NavigationLinks = () => (
  <ul className="flex flex-col sm:flex-row justify-center items-center">
    {navigationLinks.map((link) => (
      <li key={link.name}>
        <a href={link.href}>{link.name}</a>
      </li>
    ))}
  </ul>
);

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    themeChange(false);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`navbar bg-base-100 fixed top-0 left-0 w-full z-40 ${
        isScrolled ? 'shadow' : ''
      }`}
    >
      <div className="flex-1">
        <a className="btn btn-ghost text-xl" href="/">
          <img
            src="\imgs\netds_logo.png"
            alt="NetDs Logo"
            className="h-10 w-auto"
          />
        </a>
      </div>

      <div className="gap-8">
        <div>
          <img
            src="\imgs\VeteranOwned_Logo_300x300.png"
            className="hidden lg:block h-24 w-auto"
            alt="veteran owned business"
          />
        </div>
        <ul className="menu menu-horizontal items-center px-1 font-semibold">
          <li>
            <ThemeChanger />
          </li>
          {document.documentElement.clientWidth < 768 ? (
            <div className="dropdown dropdown-end">
              <div tabIndex={0} className="btn m-1">
                Menu
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                {navigationLinks.map((link) => (
                  <li key={link.name}>
                    <a href={link.href}>{link.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <NavigationLinks />
          )}
        </ul>
      </div>
    </div>
  );
}
