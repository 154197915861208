import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-32">
      <h1 className="text-2xl font-bold text-center mb-4">Privacy Policy</h1>
      <p>
        Your privacy is important to us. It is Network Data and Solutions's
        policy to respect your privacy regarding any information we may collect
        from you across our website, netds.tech
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Information we collect
      </h2>
      <p>The personal information that we may collect is outlined below:</p>
      <ul className="list-disc list-inside">
        <li>
          Personal information you provide to us when you participate in any
          interactive features, surveys, contests, promotions, sweepstakes,
          activities or events.
        </li>
        <li>
          Information collected automatically from your browser or mobile
          device.
        </li>
        <li>Information collected through cookies and similar technology.</li>
      </ul>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        How we use your information
      </h2>
      <p>We use the information we collect in various ways, including:</p>
      <ul className="list-disc list-inside">
        <li>
          To provide, operate, and maintain ourwebsite and services, including
          to display customized content and facilitate communication with you.
        </li>
        <li>
          To enhance, personalize, and improve your user experience, including
          remembering your preferences and settings.
        </li>
        <li>
          For analytics and research purposes, such as understanding how users
          interact with our website and services to improve them.
        </li>
        <li>
          For security and fraud prevention, ensuring network and data security,
          and protecting against abuse.
        </li>
        <li>
          To comply with legal and regulatory obligations, resolve disputes, and
          enforce our agreements.
        </li>
      </ul>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Sharing of your information
      </h2>
      <p>We may share your information in the following circumstances:</p>
      <ul className="list-disc list-inside">
        <li>
          With service providers and contractors who support our business
          operations, such as cloud storage providers, payment processors, and
          customer support software.
        </li>
        <li>
          With analytics and advertising partners to understand user behavior
          and provide tailored content and advertising.
        </li>
        <li>
          In response to legal requests or to comply with laws, if we believe
          the disclosure is required by law, regulation, or legal process.
        </li>
        <li>
          If we believe your actions are inconsistent with our user agreements
          or policies, or to protect the rights, property, and safety of
          ourselves and others.
        </li>
        <li>
          With your consent or at your direction, including if we notify you
          that the information you provide will be shared in a particular manner
          and you provide such information.
        </li>
      </ul>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Your rights and choices
      </h2>
      <p>
        You have certain rights and choices regarding your information. You may:
      </p>
      <ul className="list-disc list-inside">
        <li>
          Opt out of marketing communications we send you at any time by
          following the unsubscribe instructions in the message.
        </li>
        <li>
          Request access to, correction, or deletion of your personal
          information.
        </li>
        <li>
          Object to the processing of your personal information in certain
          circumstances.
        </li>
        <li>
          Request that we restrict the processing of your personal information
          in certain circumstances.
        </li>
        <li>
          Withdraw your consent for us to process your personal information,
          where our processing is based on consent.
        </li>
      </ul>
      <p>
        You can exercise these rights by contacting us using the details
        provided in the "Contact Us" section below.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Data retention</h2>
      <p>
        We retain your personal information only for as long as necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        information to the extent necessary to comply with our legal
        obligations, resolve disputes, enforce our agreements, and protect our
        legal rights.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Children's privacy</h2>
      <p>
        Our website and services are not intended for children under the age of
        13. We do not knowingly collect or solicit personal information from
        children under 13. If you become aware that a child has provided us with
        personal information, please contact us so that we can delete the
        information.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">
        Changes to this policy
      </h2>
      <p>
        We may update our Privacy Policy from time to time. We encourage you to
        review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.
      </p>
    </div>
  );
}
