import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ServiceObject } from "../Interfaces";
import { serviceItems } from "../data/ServiceData";

const ServiceCard = ({ item, index, setActiveIndex, navigate, SVG }: any) => {
  const handleCardClick = () => {
    let tmpItem = { ...item, svgKey: item.SVG.key };
    delete tmpItem.SVG;
    navigate("/service", {
      state: {
        data: tmpItem,
      },
    });
  };

  return (
    <div
      className="bg-base-200 rounded-lg shadow-md p-4 flex flex-col card relative transition duration-300 ease-in-out hover:shadow-xl hover:scale-105 cursor-pointer"
      onMouseEnter={() => setActiveIndex(index)}
      onMouseLeave={() => setActiveIndex(null)}
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      aria-label={`View details for ${item.title}`}
    >
      <div
        style={{ backgroundColor: item.color }}
        className="w-full h-1 top-0 left-0 transition duration-300 ease-in-out"
      ></div>
      <div className="p-4 flex flex-col h-full">
        <h3 className="text-xl font-bold my-2 text-start">{item.title}</h3>
        <p className="text-start font-semibold">{item.description}</p>
        <div className="mt-auto text-gray-600 text-sm flex justify-between items-center">
          <span className="absolute bottom-1 right-[37%] font-semibold opacity-75">
            Click for more
          </span>
          <div
            className="bg-accent p-2 rounded-full scale-125 fill-current text-base"
            style={{ position: "absolute", bottom: 0, right: 0 }}
          >
            {SVG}
          </div>
        </div>
      </div>
    </div>
  );
};

const OurServices = forwardRef<HTMLDivElement>((_, ref) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate();

  return (
    <div className="container mx-auto py-12 text-base relative" ref={ref}>
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold mb-8 text-center">Our Services📜</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {serviceItems.map((item, index) => (
            <ServiceCard
              key={index}
              item={item}
              index={index}
              setActiveIndex={setActiveIndex}
              navigate={navigate}
              SVG={item.SVG}
            />
          ))}
        </div>
      </div>
      <div
        className={`absolute bottom-[0%] left-0 right-0 overflow-hidden -z-40`}
      >
        <motion.div
          className=""
          initial={{ y: 0 }}
          animate={{ y: activeIndex !== null ? -20 : 0 }} // Example animation based on active index
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          {/* SVG goes here */}
          <svg
            id="wave"
            viewBox="0 0 1440 490"
            xmlns="http://www.w3.org/2000/svg"
            className="scale-[1.2]"
          >
            <defs>
              <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                <stop stopColor="rgba(243, 106, 62, 1)" offset="0%"></stop>
                <stop stopColor="rgba(255, 179, 11, 1)" offset="100%"></stop>
              </linearGradient>
            </defs>
            <path
              fill="url(#sw-gradient-0)"
              d="M0,196L34.3,236.8C68.6,278,137,359,206,359.3C274.3,359,343,278,411,253.2C480,229,549,261,617,285.8C685.7,310,754,327,823,310.3C891.4,294,960,245,1029,187.8C1097.1,131,1166,65,1234,89.8C1302.9,114,1371,229,1440,253.2C1508.6,278,1577,212,1646,212.3C1714.3,212,1783,278,1851,269.5C1920,261,1989,180,2057,179.7C2125.7,180,2194,261,2263,302.2C2331.4,343,2400,343,2469,302.2C2537.1,261,2606,180,2674,155.2C2742.9,131,2811,163,2880,204.2C2948.6,245,3017,294,3086,294C3154.3,294,3223,245,3291,220.5C3360,196,3429,196,3497,236.8C3565.7,278,3634,359,3703,375.7C3771.4,392,3840,343,3909,310.3C3977.1,278,4046,261,4114,220.5C4182.9,180,4251,114,4320,81.7C4388.6,49,4457,49,4526,73.5C4594.3,98,4663,147,4731,163.3C4800,180,4869,163,4903,155.2L4937.1,147V490H0Z"
            ></path>
          </svg>
        </motion.div>
      </div>
    </div>
  );
});

export default OurServices;
