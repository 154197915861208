// SVGBackground.tsx

import React from 'react';

interface SVGBackgroundProps {
  topOffset?: number; // Optional prop to adjust top offset
}

const SVGBackground: React.FC<SVGBackgroundProps> = ({ topOffset = 20 }) => {
  return (
    <div className="absolute inset-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        className="w-screen h-screen fill-current text-accent"
      >
        <defs>
          <linearGradient id="grad1_0" x1="43.8%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="14.444444444444446%"
              stopColor="#001220"
              stopOpacity="1"
            />
            <stop
              offset="85.55555555555554%"
              stopColor="#001220"
              stopOpacity="1"
            />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="grad2_0" x1="0%" y1="0%" x2="56.3%" y2="100%">
            <stop
              offset="14.444444444444446%"
              stopColor="#001220"
              stopOpacity="1"
            />
            <stop
              offset="85.55555555555554%"
              stopColor="#001220"
              stopOpacity="1"
            />
          </linearGradient>
        </defs>
        <g transform="translate(1920, 0)">
          <path
            d="M0 810C-65.2 786.6 -130.3 763.2 -201.4 751.5C-272.4 739.8 -349.2 739.8 -405 701.5C-460.8 663.2 -495.4 586.6 -515.5 515.5C-535.6 444.3 -541.1 378.6 -563.8 325.5C-586.5 272.4 -626.4 231.8 -670.4 179.6C-714.3 127.5 -762.1 63.7 -810 0L0 0Z"
            style={{
              transition: 'all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s',
            }}
          />
        </g>
        <g transform="translate(0, 1080)">
          <path
            d="M0 -810C53.3 -730.4 106.7 -650.8 169.3 -631.7C231.9 -612.6 303.8 -654 381 -659.9C458.2 -665.8 540.8 -636.3 572.8 -572.8C604.7 -509.2 586 -411.6 593.2 -342.5C600.5 -273.4 633.8 -233 674.2 -180.7C714.7 -128.3 762.3 -64.2 810 0L0 0Z"
            style={{
              transition: 'all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s',
            }}
          />
        </g>
      </svg>
    </div>
  );
};

export default SVGBackground;
