export default function OurStory() {
  return (
    <div className="container mx-auto py-12 font-semibold text-base">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold mb-6 text-center">Our Story🌳</h2>
        <p className="text-lg leading-relaxed mb-8 text-start">
          At NETDS, our professional team is committed to excellence in
          technical service delivery, backed by more than 14 years of combined
          experience in IT and software. We understand the complexities and
          ongoing challenges businesses and the commercial industry encounter
          with evolving technology. Our services are comprehensive and
          customized to meet each client's unique needs, distinguishing us from
          others who may offer generic solutions. We prioritize open
          communication and transparent pricing, ensuring that you are always
          well-informed about the processes, and the honest cost of the services
          you are getting.
        </p>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="https://live.staticflickr.com/8218/8437956869_66d8b38f1b.jpg"
              alt="Our Story"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h3 className="text-2xl font-bold mb-4">Our Mission</h3>
            <p className="leading-relaxed mb-6">
              We aim to offer effective technical solutions with clear pricing
              and a thorough grasp of your challenges. Our goal is to support
              your growth and success as a trusted partner.
            </p>
            <h3 className="text-2xl font-bold mb-4">Our Vision</h3>
            <p className="leading-relaxed">
              At NETDS, our vision is to simplify and enhance everyday life
              through innovative technical solutions. We are committed to being
              a trusted partner who understands your unique needs and delivers
              solutions that make technology seamlessly integrate into your
              daily operations. By prioritizing user-friendly, efficient, and
              effective services, we strive to make your life easier and help
              you achieve your goals with confidence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
