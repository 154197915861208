import { useState, ChangeEvent, FormEvent } from 'react';
import { EmailJSHelper } from '../utility/EmailJSHelper';
import { serviceItems } from '../data/ServiceData';

// Define the structure for the form data using an interface
interface FormData {
  name: string;
  email: string;
  services: string;
  inquiry: string;
}

export default function ContactForm() {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    services: '',
    inquiry: '',
  });
  const [submitted, setSubmitted] = useState<string>('false');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    if (submitted === 'submitted') {
      // refresh
      window.location.reload();
    }
    e.preventDefault();
    setSubmitted('true');
    // send email
    EmailJSHelper.sendEmailDebounced(formData);
    setTimeout(() => {
      setSubmitted('submitted');
    }, 3000);
  };

  return (
    <div className="bg-base-200 p-6 md:rounded-r-lg max-w-4xl mx-auto w-full font-semibold">
      <h1 className="text-center text-5xl pb-6 p-2">Contact Us 📧</h1>
      <form onSubmit={handleSubmit} className="space-y-6 ">
        {submitted === 'submitted' ? (
          <></>
        ) : (
          <>
            <div className="">
              {/* name */}
              <label className="input-group">
                {/* <span className="w-20">Name</span> */}
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  className="input input-bordered w-full my-2"
                  required
                />
              </label>
              {/* email */}
              <label className="input-group ">
                {/* <span className="w-20">Email</span> */}
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  className="input input-bordered w-full my-2"
                  required
                />
              </label>
              {/* company name */}
              <label className="input-group ">
                {/* <span className="w-20">Company Name</span> */}
                <input
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  // value={formData.company}
                  onChange={handleChange}
                  className="input input-bordered w-full my-2"
                  required
                />
              </label>
              {/* company industry dropdown */}
              <label className="input-group ">
                {/* <span className="w-20">Industry</span> */}
                <select
                  name="industry"
                  // value={formData.industry}
                  onChange={handleChange}
                  className="select select-bordered w-full my-2"
                  required
                >
                  <option value="" disabled selected>
                    What industry are you in?
                  </option>
                  <option value="healthcare">Healthcare</option>
                  <option value="legal">Legal</option>
                  <option value="finance">Finance</option>
                  <option value="technology">Technology</option>
                  <option value="education">Education</option>
                  <option value="nonprofit">NonProfit</option>
                  <option value="other">Other</option>
                </select>
              </label>
              {/* number of employees dropdown */}
              <label className="input-group ">
                {/* <span className="w-20">Number of Employees</span> */}
                <select
                  name="employees"
                  // value={formData.employees}
                  onChange={handleChange}
                  className="select select-bordered w-full my-2"
                  required
                >
                  <option value="" disabled selected>
                    How many employees do you have?
                  </option>
                  <option value="1-10">1-10</option>
                  <option value="11-50">11-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="500+">500+</option>
                </select>
              </label>
              {/* drop down with services */}
              <label className="input-group ">
                {/* <span className="w-20">Services</span> */}
                <select
                  name="services"
                  // value={formData.services}
                  onChange={handleChange}
                  className="select select-bordered w-full my-2"
                  required
                >
                  <option value="" disabled selected>
                    What services are you interested in?
                  </option>
                  {serviceItems.map((item, index) => (
                    <option key={index} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </label>
              {/* inquiry */}
              <label className="input-group ">
                {/* <span className="w-20">Inquiry</span> */}
                <textarea
                  name="inquiry"
                  placeholder="Please provide a brief description of your inquiry."
                  value={formData.inquiry}
                  onChange={handleChange}
                  className="textarea textarea-bordered w-full my-2"
                  required
                ></textarea>
              </label>
            </div>
          </>
        )}
        <button type="submit" className="btn btn-accent w-full">
          {submitted === 'true' ? (
            <span className="loading loading-ring loading-md"></span>
          ) : submitted === 'submitted' ? (
            'Submitted, Thank You!'
          ) : (
            'Submit'
          )}
        </button>
      </form>
    </div>
  );
}
