import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Service() {
  const location = useLocation();
  const service = location.state?.data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // This will trigger the scroll to top on component mount

  if (!service) {
    return (
      <div className="p-4 text-center text-lg ">No service data found.</div>
    );
  }

  return (
    <div className="container mx-auto p-4 md:p-8">
      {/* fixed background svg container */}
      <div className="hidden md:block absolute top-0 left-0 w-full h-full -z-10">
        <svg
          id="sw-js-blob-svg"
          viewBox="0 0 100 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          {' '}
          <defs>
            {' '}
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              {' '}
              <stop
                id="stop1"
                stop-color="rgba(248, 117, 55, 1)"
                offset="0%"
              ></stop>{' '}
              <stop
                id="stop2"
                stop-color="rgba(251, 168, 31, 1)"
                offset="100%"
              ></stop>{' '}
            </linearGradient>{' '}
          </defs>{' '}
          <path
            fill="url(#sw-gradient)"
            d="M22.9,-26.6C28.6,-27.3,31.5,-19.2,31.3,-12C31.1,-4.8,27.7,1.6,23.4,5.4C19.1,9.2,13.8,10.5,9.7,10.5C5.7,10.6,2.8,9.5,0.4,9C-2,8.4,-4.1,8.4,-4.3,7.1C-4.6,5.8,-3.1,3.1,-8.5,-0.3C-13.9,-3.8,-26.1,-8.1,-29.9,-13.7C-33.6,-19.3,-28.8,-26.2,-22.4,-25.3C-16,-24.3,-8,-15.7,0.3,-16.1C8.6,-16.5,17.1,-25.9,22.9,-26.6Z"
            width="100vw"
            height="100vh"
            transform="translate(25 40)"
            stroke-width="0"
          ></path>{' '}
        </svg>
      </div>
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-6 pt-32 lg:pt-24">
        {service.title}
      </h1>
      <div className="flex justify-center items-center">
        <div className="xl:max-w-[70%] bg-base-100 p-6 rounded-lg shadow-lg">
          <p className="text-md md:text-lg lg:text-xl mb-8">
            {service.description}
          </p>
          {service.key_features && (
            <div className="mb-8">
              <h2 className="text-2xl md:text-3xl font-bold mb-5">
                Key Features
              </h2>
              <ul className="list-disc list-inside space-y-2">
                {service.key_features.map((feature, index) => (
                  <li key={index}>
                    <strong className="font-semibold">{feature.name}:</strong>{' '}
                    {feature.desc}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {service.pricing && (
            <div className="mb-8">
              <h2 className="text-2xl md:text-3xl font-bold mb-5">Pricing</h2>
              {service.pricing.map((tier, index) => (
                <div
                  key={index}
                  className="mb-6 bg-accent p-3 rounded-lg text-base-content"
                >
                  <h3 className="text-xl md:text-2xl font-semibold mb-1">
                    {tier.tier}
                  </h3>
                  <div className="flex flex-row">
                    <p className="text-lg mb-3">
                      Price:{' '}
                      {tier.price.toLowerCase() !== 'contact us' ? (
                        tier.price
                      ) : (
                        <>
                          <a
                            href="https://appointment.netds.tech/"
                            className="btn btn-accent-content mx-1"
                          >
                            Appointment
                          </a>
                          <a
                            href="/contact"
                            className="btn btn-accent-content mx-1"
                          >
                            Email
                          </a>
                        </>
                      )}
                    </p>
                  </div>
                  {tier.features && (
                    <ul className="list-disc list-inside space-y-1">
                      {tier.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="mt-4" style={{ color: service.color }}>
            {service.SVG}
          </div>
        </div>
      </div>
    </div>
  );
}
