import { useState } from 'react';

export default function ThemeChanger() {
  // State to hold the current theme
  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');

  // Function to handle theme change
  const handleThemeChange = (newTheme: string) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div className="dropdown p-0">
      <div tabIndex={0} role="button" className="btn m-1">
        Theme
        <svg
          width="12px"
          height="12px"
          className="h-2 w-2 fill-current opacity-60 inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2048 2048"
        >
          <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
        </svg>
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] p-2 shadow-2xl bg-base-300 rounded-box w-72 grid grid-cols-3 translate-y-[5.25rem] -translate-x-36 md:-translate-x-20"
      >
        {['light', 'retro', 'cyberpunk', 'cmyk', 'business'].map(
          (themeName) => (
            <li key={themeName}>
              <input
                type="radio"
                name="theme-dropdown"
                className="theme-controller btn btn-sm btn-block btn-ghost justify-start"
                aria-label={
                  themeName.charAt(0).toUpperCase() + themeName.slice(1)
                }
                value={themeName}
                checked={theme === themeName}
                onChange={() => handleThemeChange(themeName)}
              />
            </li>
          )
        )}
      </ul>
    </div>
  );
}
