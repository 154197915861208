import { motion } from 'framer-motion';
import React from 'react';

const BouncingDots: React.FC = () => {
  // Define color and size variants
  const colors = ['#ff4500', '#32cd32', '#1e90ff']; // Red, Green, Blue
  const sizes = ['w-3 h-3', 'w-4 h-4', 'w-5 h-5']; // Different sizes

  const dotVariants = (index: number) => ({
    animate: {
      y: ['100%', '-100%'],
      rotate: [0, 360], // Full rotation
      transition: {
        y: {
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 0.8,
          ease: 'easeInOut',
        },
        rotate: {
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2, // Slower rotation for a distinct effect
          ease: 'linear',
        },
      },
      backgroundColor: colors[index % colors.length], // Cycle through colors
    },
  });

  return (
    // Full viewport container with flex centering
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex space-x-2">
        {Array.from({ length: 3 }).map((_, index) => (
          <motion.div
            key={index}
            className={`rounded-full ${sizes[index % sizes.length]}`}
            style={{ backgroundColor: colors[index % colors.length] }} // Inline styles for initial color
            variants={dotVariants(index)}
            animate="animate"
          />
        ))}
      </div>
    </div>
  );
};

export default BouncingDots;
