import React from 'react';

// Define the props interface if you're using TypeScript, otherwise, you can skip this part
interface HeroProps {
  backgroundImage: string;
  logoImage: string;
  logoAltText: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  servicesRef?: any;
}

export default function Hero({
  backgroundImage,
  logoImage,
  logoAltText,
  description,
  buttonText,
  buttonLink,
  servicesRef,
}: HeroProps) {
  const scrollToServices = () => {
    if (servicesRef.current) {
      const topPosition =
        servicesRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 75; // Adjust this value to change where it stops, 100px above the element
      window.scrollTo({
        top: topPosition - offset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="pt-24">
      {' '}
      {/* Adjusted here to use h-full instead of min-h-screen */}
      <div
        className="hero h-[90vh] rounded-lg" // Adjust height class here as well
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="hero-overlay rounded-lg"></div>
        <div className="hero-content text-center text-neutral-content glass rounded-xl px-4 mx-2 md:px-12 md:py-4">
          <div className="flex flex-col gap-3 max-w-md">
            <div className="text-xl flex items-center justify-center">
              <img src={logoImage} alt={logoAltText} className="h-14 w-auto" />
            </div>
            <div className="flex flex-col w-auto gap-3">
              <p className="text-left font-semibold text-sm">{description}</p>
              <button className="btn btn-primary" onClick={scrollToServices}>
                {/* <a href={buttonLink}>{buttonText}</a> */}
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
