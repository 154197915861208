import React, { useState } from 'react';
import ContactForm from '../components/ContactForm';
import BouncingDots from '../components/BouncingDots';
import Navbar from '../components/Navbar';
import SVGBackground from '../components/SVGBubbles'; // Import the SVGBackground component

interface SidePanelProps {
  imageUrl: string;
  title: string;
  text: string;
}

const SidePanel: React.FC<SidePanelProps> = ({ imageUrl, title, text }) => {
  return (
    <div
      className="flex flex-col justify-center p-8 w-full min-h-full md:rounded-l-lg bg-cover bg-center"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="glass p-8 rounded-lg xl:w-[72%] text-gray-900">
        <h1 className="text-xl font-bold mb-2">{title}</h1>
        <p className="font-semibold">{text}</p>
      </div>
    </div>
  );
};

const Contact = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen pt-20">
      <SVGBackground /> {/* Render the SVGBackground component */}
      {loading ? (
        <BouncingDots />
      ) : (
        <div className="container flex flex-col md:flex-row h-full bg-base-300 p-4 md:rounded-lg relative z-10">
          {/* Side Panel */}
          <SidePanel
            imageUrl="/imgs/networking-image.jpg"
            title="We are the professionals you need!"
            text="We understand that reaching out for help can feel daunting. That's why we encourage you to ask us anything—no question is too small or too complex. Having faced similar challenges ourselves, we're not just here to solve your problem; we're here to help you understand it. Together, we'll find a solution that fits your unique needs."
          />

          {/* Contact Form */}
          <div className="flex flex-col flex-grow justify-center">
            <ContactForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
