import { useState, useRef } from 'react';
import BouncingDots from '../components/BouncingDots';
import Hero from '../components/Hero';
import OurStory from '../components/OurStory';
import OurServices from '../components/OurServices';

function Home() {
  const [loading, setLoading] = useState(false);
  const servicesRef = useRef<any>(null);

  return (
    <div>
      {loading ? (
        <>
          <BouncingDots />
        </>
      ) : (
        <>
          <div className="flex items-center justify-center w-full">
            <div className="flex flex-col gap-2 p-4 min-w-[100%] md:min-w-[80%]">
              <Hero
                backgroundImage="https://upload.wikimedia.org/wikipedia/commons/0/03/WEB_Technology.jpg"
                logoImage="/imgs/netds_logo.png"
                logoAltText="NETDS Logo"
                description="We at NETDS, a Veteran-owned technical solutions provider, are dedicated to meeting your specific technical needs with bespoke solutions. Discover the difference with NETDS, where we prioritize your unique requirements. Our team of experts is passionate about leveraging technology to enhance your commercial properties, business efficiency, and growth. We believe in building relationships, understanding your challenges, and delivering tailored services that propel your goals forward!"
                buttonText="Our Services"
                buttonLink="/services"
                servicesRef={servicesRef}
              />
              <OurStory />
              <OurServices ref={servicesRef} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
